.overlayBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 100vw;
  z-index: 800;

  &.isActive {
    left: 0vw;
  }

  .overlayBoxOpacity {
    background: rgba(255, 255, 255, 0);
    height: 100%;
    width: 100%;
    transition-duration: 0.5s;
  }

  .isOpacityActive {
    background: rgba(255, 255, 255, 1);
  }
}