.main-header {
  padding: 48px 0px;
  width: 100%;
  font-weight: 500;
  font-size: 3.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #434343;

  @media all and (max-width: 1600px) {
    font-size: 2.8rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 2.6rem;
  }

}

.main-header-dev {
  padding: 48px 0px;
  width: 100%;
  font-weight: bold;
  font-size: 4.2rem;
  text-transform: uppercase;
  color: black;

  @media all and (max-width: 1600px) {
    font-size: 3.6rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 3rem;
  }

  @media all and (max-width: 900px) {
    padding: 32px 0px;
  }

  .brakBar {
    height: 1px;
    background: black;
    width: 64px;
    margin-top: 32px;

    @media all and (max-width: 900px) {
      margin-top: 16px;
      width: 48px;
    }
  }
}