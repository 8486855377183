.eu-section {
  padding-top: 64px;
  padding-bottom: 64px;

  @media all and (max-width: 900px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .container {
    border-top: 1px solid #009DD8;
    padding-top: 64px;
    .euImg {
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      font-weight: 400;
    }

    ul {
      padding-inline-start: 16px;
    }

    @media all and (max-width: 700px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}