.contact-section {
  padding-top: 96px;
  padding-bottom: 96px;


  @media all and (max-width: 1200px) {
    padding-top: 0px;
    padding-bottom: 48px;

    // .container {
    //   padding-left: 32px;
    //   padding-right: 32px;
    // }

    .contact-setion-mainRow {
      flex-direction: column-reverse;

      .main-header-dev {
        padding-top: 48px;

        @media all and (max-width: 900px) {
          padding-left: 8px;

          .brakBar {
            margin-left: 2px;
          }
        }
      }
    }
  }

  @media all and (max-width: 900px) {
    padding-top: 0px;
    padding-bottom: 64px;

    .container {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media all and (max-width: 600px) {
    padding-top: 16px;
    padding-bottom: 16px;
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .mapCol {
    @media all and (min-width: 1201) {
      padding-left: 0px !important;
    }
  }


  .contactCol {
    @media all and (max-width: 900px) {
      padding-left: 48px !important;
      padding-right: 48px !important;
    }
    @media all and (max-width: 600px) {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    .contact-form {
      margin-top: -24px;

      @media all and (max-width: 1200px) {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: -8px;
      }

      @media all and (max-width: 900px) {
        margin-left: 0px;
        margin-right: 0px;
      }


      @media (max-width: 600px) {
        padding-left: 8px;
        padding-right: 8px;
      }

      button {
        margin-left: auto;
        margin-right: 0px;
        width: calc(50% - 16px);
        justify-content: center;

        @media all and (max-width: 1200px) {
          margin-right: 8px;
          margin-top: 32px;
        }
      }
    }
  }
}