.action-block {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 64px;

  .action-block__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    .action-block__title {
      transition-duration: 1s;
      font-size: 24px;
      transition-timing-function: cubic-bezier(0.015, 0.42, 0.065, 1);
    }

    img {
      margin-top: 16px;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.015, 0.42, 0.065, 1);
    }

    &:hover {
      .action-block__title {
        margin-right: 128px;
      }

      img {
        margin-left: 128px;
      }
    }
  }
}