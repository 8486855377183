.heroTiles-section-dev-two {
  padding-top: 32px;

  @media all and (max-width: 1200px) {
    padding-top: 16px;
  }

  @media all and (max-width: 900px) {
    padding-top: 8px;

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}