.cookies-info-box {
  width: 42%;
  position: fixed;
  display: flex;
  bottom: 8px;
  left: 30%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  border: 1px solid black;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(15px);
  z-index: 999999999;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 8px;

  @media all and (max-width: 1300px) {
    width: 60%;
    left: 20%;
  }

  @media all and (max-width: 1100px) {
    width: 80%;
    left: 10%;
  }

  @media all and (max-width: 1100px) {
    left: 8px;
    width: calc(100% - 16px);
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 400;
  }

  p {
    margin-top: 5px;
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 0px;
  }

  .right-side {
    padding: 10px;
    min-width: 140px;
    text-align: left;
  }

  .left-side {
    padding: 10px;
    width: auto;
    font-size: 1.1rem;
  }

  .box {
    width: 100%;
    margin: 5px;
    text-align: center;
  }

  .btn-material-nav {
    width: 100%;
    background: black;
    text-align: center;
    margin: auto;
    line-height: 240%;
    outline: none;
    user-select: none;
    cursor: default;
    font-size: 1.4rem;
    font-weight: 500;
    word-wrap: nowrap;
    white-space: nowrap;
    cursor: pointer;
    color: white;

    &:focus {
      background: #f2f2f2;
      color: #333;
    }
  }

  .privacy {
    text-align: center;
    margin: auto;
    font-size: 1.4rem;
    margin-top: 10px;
    color: black;
    cursor: default;
    outline: none;
    user-select: none;

    &:hover {
      cursor: pointer;
      color: #0d408c;
    }

    &:focus {
      cursor: pointer;
    }
  }

  @media all and (max-width: 600px) {
    flex-wrap: wrap;

    .right-side {
      width: 100%;
    }

    .left-side {
      width: 100%;
      padding-bottom: 0;
    }
  }
}