a {
  text-decoration: none;
  color: black;
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  min-height: 30px !important;
  color: white !important;
  font-weight: 600 !important;
}

.toasts-container {
  top: 25px !important;
  // right: 25px !important;

  .toast {
    font-size: 1.5rem !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.overlay {
  transform: scale(1);
  transition-duration: 0.3s;
}

.overlay.isActive {
  transform: scaleX(0.97) scaleY(0.99);
  transition-duration: 0.3s;
  opacity: 0.30;
  filter: blur(30px);
}

.label {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}