.standard-text-block {
  font-size: 1.4rem;

  img {
    width: 100%;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 0px;
    color: black;
  }

  .title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
    color: #434343;

    @media all and (max-width: 900px) {
      line-height: 1.4;
      letter-spacing: 0.025em;
    }
  }

  .btn {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    border: none;
    min-width: 256px;

    @media all and (max-width: 1200px) {
      min-width: 0px;
      padding: 8px 32px;
    }

    cursor: pointer;

    &:hover {
      box-shadow: inset 0px 0px 1000px rgba(0, 0, 0, 0.05);
    }

    &:active {
      box-shadow: inset 0px 0px 1000px rgba(0, 0, 0, 0.15);
    }

    &.btn-dev-outline {
      color: white;
      background: transparent;
      border: 1px solid white;
      font-weight: 400;

      &:hover {
        box-shadow: inset 0px 0px 1000px rgba(255, 255, 255, 0.15);
      }

      &:active {
        color: black;
        background: white;
        box-shadow: inset 0px 0px 1000px rgba(255, 255, 255, 1);
      }
    }

    &.btn-piw {
      color: white;
      background: #009DD8;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  strong {
    font-weight: 600;
  }

  &.justify {
    text-align: justify;

    p {
      text-align: justify;
    }
  }


  .center {
    text-align: center;
  }

  &.white {
    .title {
      color: white;
    }

    p {
      color: white;
    }
  }
}