$sub-color: #999;
$main-color: black;

.checkbox {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 8px;
  cursor: pointer;

  a {
    color: #F29201;
  }

  .checkbox__rect {
    font: inherit;
    background: none;
    color: $main-color;
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;
    max-height: 24px;
    min-height: 24px;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 16px;
    border-radius: 4px;

    &.isActive{
      background: #F29201;
      box-shadow: inset 0px 0px 0px 5px white;
    }

    &:focus {
      outline: none;
      box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
    }

    &:focus~.form-input-label-off {
      display: none;
    }
  }

  .checkbox__label {
    // transit
  }
}