.oferta-section {
  padding-top: 64px;
  padding-bottom: 64px;

  @media all and (max-width: 900px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .container {
    @media all and (max-width: 900px) {
      padding-right: 8px;
      padding-left: 8px;
    }

    @media all and (max-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .colorContainer {
    background: rgba(0, 157, 216, 0.2);
    // padding: 32px 0px;

    .toRight {
      display: inline-flex;
      margin-top: 8px;
      float: right;
    }
  }

  .colorContainer-dev {
    background: linear-gradient(270deg, rgba(242, 90, 44, 0.8) 0%, rgba(242, 146, 1, 0.8) 100%);

    .standard-text-block {
      p {
        color: white;
      }
    }
  }
}

.oferta-section-piw{
  @media all and (max-width: 900px) {
    padding-top: 0px;

    .main-header{
      padding-left: 16px;
    }
  }
}