.references-section {
  padding-top: 64px;
  padding-bottom: 64px;

  @media all and (max-width: 900px) {
    padding-top: 0px;
  }

  .referenceBlock {
    justify-self: center;
    margin: auto;
    text-align: center;

    img {
      max-width: 200px;
    }
  }

  .swiperWrapper {
    position: relative;
    z-index: 3;


    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .cursor-pointer__left,
    .cursor-pointer__right {
      cursor: pointer;
      z-index: 999;
      width: 64px;
      top: 64px;
      position: absolute;
      z-index: 3;
      box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.5);

      @media all and (max-width: 900px) {
        width: 48px;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }

    .cursor-pointer__right {
      right: 0px;
      position: absolute;
      transform: translateX(-8px);
      transition-duration: 0.3s;

      &:active {
        transform: translateX(0px);
      }
    }

    .cursor-pointer__left {
      transform: translateX(8px);
      transition-duration: 0.3s;

      &:active {
        transform: translateX(0px);
      }
    }
  }
}