.full-img-section-container {
  @media all and (max-width: 900px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media all and (max-width: 700px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.full-img-section {
  width: 100%;
  padding-bottom: 256px;
  padding-top: 256px;
  background-size: cover;
  background-position: center;
  margin-top: 32px;
  margin-bottom: 32px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

  @media all and (max-width: 1600px) {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  }

  @media all and (max-width: 900px) {
    margin-top: 8px;
    box-shadow: none;
  }

  @media all and (max-width: 700px) {
    padding: 192px 32px;
  }

  @media all and (max-width: 500px) {
    padding: 128px 16px;
  }

  .textBox {
    margin: auto;
    color: white;
    max-width: 720px;
    font-size: 3.6rem;
    text-align: center;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    font-weight: bold;

    @media all and (max-width: 900px) {
      font-size: 3.2rem;
    }

    @media all and (max-width: 700px) {
      backdrop-filter: brightness(0.5);
      font-size: 2.8rem;
    }

    padding: 16px;
  }
}