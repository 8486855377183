.offerBlock {
  @media all and (max-width: 1200px) {
    border-top: 8px solid white;
    border-bottom: 8px solid white;
  }

  .isReversed,
  .isNotReversed {

    @media all and (max-width: 900px) {
      flex-direction: column-reverse !important;
    }

    .imgCol {
      justify-content: center;
      align-items: center;
      display: flex;

      @media all and (max-width: 900px) {
        height: 420px;
      }

      @media all and (max-width: 600px) {
        height: 320px;
      }

      .bgBox {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }

      img {
        width: 100%;
      }
    }

    .contentCol {
      align-items: center;
      display: flex;
      padding: 64px !important;

      @media all and (max-width: 1200px) {
        padding: 48px !important;
      }

      @media all and (max-width: 900px) {
        padding: 32px 24px !important;
      }
      @media all and (max-width: 700px) {
        padding: 32px !important;
      }
      @media all and (max-width: 600px) {
        padding: 32px 16px !important;
      }
    }
  }



  .isNotReversed {
    // transform: translateX(64px);
    // padding-left: 32px;
  }

  .isReversed {
    flex-direction: row-reverse;

    @media all and (max-width: 900px) {
      flex-direction: row;
    }

    // transform: translateX(-64px);
  }
}