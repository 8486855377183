.prefooter {
  padding-top: 64px;
  padding-bottom: 32px;
  margin-top: 32px;
  background: rgba(0, 157, 216, 0.2);

  @media all and (max-width: 900px) {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 0px;
  }

  .container {
    .rowInfo {
      display: flex;
      padding-top: 32px;
      padding-bottom: 32px;

      @media all and (max-width: 900px) {
        padding-top: 16px;
        padding-bottom: 16px;
        flex-direction: column;
      }

      .pt-s-3 {
        @media all and (max-width: 900px) {
          padding-top: 24px;
        }
      }

      .kontakt {
        width: 25%;

        @media all and (max-width: 900px) {
          width: 100%;
        }

        img {
          max-width: 200px;
        }
      }

      .logos {
        margin-left: auto;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 900px) {
          margin-left: 0px;
        }

        .logosWrapper {
          display: flex;

          a {
            margin-right: 8px;

            &:nth-child(2) {
              margin-right: 0px;
            }

            img {
              width: 96px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.prefoorerDev {
    background: linear-gradient(270deg, rgba(242, 90, 44, 0.8) 0%, rgba(242, 142, 5, 0.8) 100%);

    p {
      color: white;
    }
  }
}