.history-section {
  padding-top: 64px;
  @media all and (max-width: 900px) {
    padding-top: 32px;

    .action-block{
      padding-bottom: 32px;
      padding-top: 32px;
    }
  }

  .hr {
    margin-top: 96px;
    border-bottom: 1px solid #009DD8;

    @media all and (max-width: 900px) {
      display: none;
    }
  }
}