.hero-tile-dev {
  background-position: center;
  background-size: cover;
  height: 480px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

  @media all and (max-width: 1600px) {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    height: 400px;
  }

  @media all and (max-width: 900px) {
    box-shadow: none;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .orangeBelt {
    background: linear-gradient(270deg, #F1592B 30.11%, #F29201 100%);
    color: white;
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    transition-duration: 0.5s;
    position: absolute;
    bottom: 0px;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

    h3 {
      font-size: 2.4rem;
    }
  }


  .hiddenOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, rgba(242, 90, 44, 0.7) 0%, rgba(242, 142, 5, 0.7) 100%);
    backdrop-filter: blur(3px);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-duration: 0.7s;
    opacity: 0;
    transform: translateY(100%);
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);


    h3 {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 1.2;
      font-weight: 500 !important;
    }

    ul {
      padding-top: 24px;
      padding-bottom: 32px;
      font-size: 1.4rem;
      padding-inline-start: 0px;

      li {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .labelLong {
      font-size: 1.4rem;
      letter-spacing: 0.12em;
    }
  }

  &:hover {
    .hiddenOverlay {
      opacity: 1;
      transform: translateY(0%);
    }

    .orangeBelt {
      transform: translateY(100%);
    }
  }
}