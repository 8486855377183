.hero-section {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  h2,
  h1 {
    margin: auto;
    font-weight: bold;
    font-size: 12rem;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
    max-width: 1600px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);

    @media all and (max-width: 1600px) {
      font-size: 9rem;
    }

    @media all and (max-width: 1200px) {
      font-size: 6rem;
    }

    @media all and (max-width: 900px) {
      font-size: 4rem;
    }

    @media all and (max-width: 600px) {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 5rem;
    text-shadow: none;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);

    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }

    @media all and (max-width: 1200px) {
      font-size: 3rem;
    }
  }
}