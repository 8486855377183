.map-section {
  width: 100%;
  z-index: 0;
  filter: grayscale(1);

  .map {

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    // border-radius: 10px;
    width: 100%;
    height: 600px;

    @media all and (max-width: 1600px) {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      height: 550px;
    }

    @media all and (max-width: 1200px) {
      height: 500px;
    }

    @media all and (max-width: 900px) {
      box-shadow: none;
      height: 400px;
    }
  }
}