.paddings {
  .pb-5 {
    padding-bottom: 64px;

    @media all and (max-width: 900px) {
      padding-bottom: 16px;
    }
  }

  .pt-5 {
    padding-top: 64px;

    @media all and (max-width: 900px) {
      padding-bottom: 16px;
    }
  }
}