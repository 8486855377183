.navbarBarWrapper {
  position: fixed;
  width: 100%;
  z-index: 9999;
}


.navbarBar {

  width: 100%;
  height: 80px;
  display: flex;
  background: white;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);


  @media all and (max-width: 700px) {
    height: 70px;
  }
}


.navbarLogo {
  height: 100%;
  align-items: center;
  z-index: 999;
  display: flex;

  img {
    cursor: pointer;
    height: 50px;
  }

  @media all and (max-width: 1200px) {
    height: 80px;

    img {
      height: 40px;
    }
  }

  @media all and (max-width: 700px) {
    height: 64px;
    justify-content: left;

    img {
      height: 42px;
    }
  }
}

.menuBtn {
  right: 0px;
  cursor: pointer;
  z-index: 904;
  height: 80px;
  width: 80px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 27px;
  transform: translateX(27px);
  user-select: none;
  margin-left: auto;

  @media all and (max-width: 1200px) {
    height: 80px;
    width: 80px;
    padding: 27px;
    transform: translateX(20px);
    position: fixed;
    right: 24px;
    top: 0px;
  }

  @media all and (max-width: 900px) {
    height: 80px;
    right: 16px;
  }

  @media all and (max-width: 700px) {
    height: 68px;
    // right: 15px;
    padding: 24px;
    top: 3px;
    transform: translateX(24px);

  }

  img {
    width: 100%;
    height: 100%;

  }
}

.menuBtn.navbarbarActive {
  top: 24px;
}

.mobileSideMenuOverlay {
  width: 150vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  right: 0px;
  transform: translateX(150vw);
  z-index: 900;
  transition-duration: 0.3s;

  &.isActive {
    transform: translateX(45vw);
  }
}



.mobileSideMenu {
  z-index: 903;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  right: 0px;
  bottom: 0px;
  // transform: translateX(100%);
  color: black;
  text-align: center;
  transition-duration: 0.3s;
  width: 100%;

  .mobileSideMenuInsetWrapper {
    padding: 128px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    transition-duration: 0.3s;

    @media all and (max-width: 700px) {
      padding: 0px;
      padding-top: 64px;

      .topLinkWrapper {
        min-width: auto;
        width: auto;
      }
    }

    &.isScrolled {
      height: calc(100vh - 100px);
    }

  }

  a {
    display: table;
    margin-right: auto;
    margin-left: auto;
    transition-duration: 0.6s;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    min-width: 400px;
    opacity: 0;
    border-radius: 10px;

    @media all and (min-width: 701px) {
      &:hover {
        background: #f7f7f7;
        transform: scale(1.25);
        padding-left: 32px;
        padding-right: 32px;
        transition-duration: 0.1s;

      }
    }

    @media all and (max-width: 700px) {
      &:focus {
        background: #f7f7f7;
        transform: scale(1.25);
        padding-left: 32px;
        padding-right: 32px;
        transition-duration: 0.1s;

      }
    }

    &.fbLink {
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
      min-width: 0px;
      margin-top: 128px;

      img {
        padding: 16px;
        height: 64px;
      }

      @media all and (max-width: 1200px) {
        margin-top: 64px;

        img {
          padding: 16px;
          height: 48px;
        }
      }
    }
  }

  .linkLarge {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 3.6rem;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    padding-bottom: 12px;
    padding-top: 0px;
    transition-duration: 0.3s;
    transform: scale(0.5);
    opacity: 0;

    @media all and (max-width: 1200px) {
      font-size: 2.2rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .linkSmall {
    margin-top: 8px;
    margin-bottom: 8px;

    font-size: 2.8rem;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
    padding-bottom: 0px;
    padding-top: 0px;
    transition-duration: 0.3s;
    transform: scale(0.5);
    opacity: 0;

    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
    }
  }

  transform: scale(0) translateY(150vh);
  opacity: 0;
  transition-duration: 0.5s;

  &.isActive {
    opacity: 1;
    transform: scale(1) translateY(0vh);
    transition-duration: 0.4s;

    @media all and (max-width: 700px) {
      transform: scale(1) translateY(0vh);
    }

    a {
      opacity: 1;
    }

    .linkLarge,
    .linkSmall {
      opacity: 1;
      padding-bottom: 8px;
      padding-top: 8px;
      transform: scale(1);
      transition-duration: 0.3s;
      transition-duration: 0.3s;
    }
  }
}